import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store";
import {
  isUserInAllowedGroups,
  ALL_GROUPS,
} from "../../../util/feature_flags/users";
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Dropdown,
  Menu,
  Result,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  LockOutlined,
  PlusOutlined,
  TeamOutlined,
  UnlockOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { isObjectEmpty } from "../../../utils";
import AudienceMemberFilterDrawer from "./AudienceMemberFilterDrawer";
import QueryEditorDrawer from "../../QueryEditorDrawer";
import { defaultSegmentNames } from "../../../constants";
import HVCConfigDrawer from "./HVCConfigDrawer";
import { SegmentCreationDropdown } from "./SegmentCreationDropdown";
import DeleteSegmentModal from "./DeleteSegmentModal";
import InExclusionListDrawer from "./InExclusionListDrawer";
import { useLocation } from "react-router-dom";

export const SegmentsColumn = ({ refreshPageInfo }) => {
  const { projectId } = useParams();
  const userGroups = useSelector((state) => state.userGroups);
  const dispatch = useDispatch();
  const { getAudienceMemberFiltersAction, getDesiredAudienceSizesAction } =
    bindActionCreators(actionCreators, dispatch);

  // redux state
  const selectedTargetId = useSelector((state) => state.selectedTargetId);
  const s3DataUploadLogs = useSelector((state) => state.s3DataUploadLogs);
  const target = useSelector((state) => state.target);
  const project = useSelector((state) => state.project);
  const isProjectLoading = useSelector((state) => state.isProjectLoading);
  const isBreakdownLoading = useSelector((state) => state.isBreakdownLoading);
  const audienceMemberFilters = useSelector(
    (state) => state.audienceMemberFilters
  );
  const desiredAudienceSizes = useSelector(
    (state) => state.desiredAudienceSizes
  );
  const isAudienceSegmentsLoading = useSelector(
    (state) => state.isAudienceSegmentsLoading
  );
  const selectedTargetSegments = useSelector(
    (state) => state.selectedTargetSegments
  );
  const fullBreakdownResp = useSelector((state) => state.breakdown);
  const targetOrPackage = useSelector((state) => state.targetPackageTabKey);

  // local state

  const [selectedSegment, setSelectedSegment] = useState();
  const [inExListCount, setInExListCount] = useState();

  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [showAudienceMemberFilterDrawer, setShowAudienceMemberFilterDrawer] =
    useState(false);

  const [showQueryEditorDrawer, setShowQueryEditorDrawer] = useState(false);
  const [showHVCConfigDrawer, setShowHVCConfigDrawer] = useState(false);
  const [showInExclusionListDrawer, setshowInExclusionListDrawer] =
    useState(false);

  const [showDeleteSegmentModalVisible, setShowDeleteSegmentModalVisible] =
    useState(false);

  const effectiveTargetId =
    selectedTargetId === "all" ? undefined : selectedTargetId;

  const breakdown =
    selectedTargetId === "all" || targetOrPackage === "targets"
      ? fullBreakdownResp?.audience_breakdown || {}
      : fullBreakdownResp?.package_breakdown || {};

  const hvcBreakdown = fullBreakdownResp?.hvc_breakdown || {};

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const drawerOpen = queryParams.get("hvc_config") === "open";
    setShowHVCConfigDrawer(drawerOpen);
  }, [location.search]);

  useEffect(() => {
    fetchAudienceMemberFilters();
    fetchDesiredAudienceSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const fetchAudienceMemberFilters = async () => {
    try {
      getAudienceMemberFiltersAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const fetchDesiredAudienceSizes = () => {
    try {
      getDesiredAudienceSizesAction(projectId);
    } catch (error) {
      alert(error);
    }
  };

  const globalFilterCount =
    (audienceMemberFilters?.[null]?.[null]
      ? Object.values(audienceMemberFilters[null][null]).reduce(
          (agg, value) => agg + value?.filter_params_count,
          0
        )
      : 0) +
    (desiredAudienceSizes?.[null]
      ? Object.values(desiredAudienceSizes[null]).filter((f) => f.audience_size)
          .length
      : 0);

  const targetFilterCount =
    // audience member filters count
    (audienceMemberFilters?.[selectedTargetId]?.[null]
      ? Object.values(audienceMemberFilters[selectedTargetId][null]).reduce(
          (agg, value) => agg + value?.filter_params_count,
          0
        )
      : 0) +
    // audience size limit
    (desiredAudienceSizes?.[null]
      ? Object.values(desiredAudienceSizes[null]).filter((f) => f.audience_size)
          .length
      : 0);

  const filteredFirstDegreeSegmentCounts = isObjectEmpty(breakdown)
    ? null
    : effectiveTargetId === undefined
    ? breakdown?.first_degree?.segment_total || 0
    : breakdown?.first_degree?.[target?.id] || 0;

  const filteredSecondDegreeSegmentCounts = isObjectEmpty(breakdown)
    ? null
    : effectiveTargetId === undefined
    ? breakdown?.second_degree?.segment_total || 0
    : breakdown?.second_degree?.[target?.id] || 0;

  const filteredFirstDegreeHVCSegmentCounts = isObjectEmpty(hvcBreakdown)
    ? null
    : effectiveTargetId === undefined
    ? hvcBreakdown?.first_degree?.segment_total || 0
    : hvcBreakdown?.first_degree?.[target?.id] || 0;

  const segmentDropdown = (segment) => (
    <Menu>
      <Menu.Item
        icon={<EditOutlined />}
        key="edit-queries"
        disabled={selectedTargetId === "all"}
        onClick={(e) => {
          setShowQueryEditorDrawer(true);
          setSelectedSegment(segment);
        }}
      >
        Edit Queries
      </Menu.Item>
      <Menu.Item
        className="danger-link"
        icon={<DeleteOutlined />}
        key="delete-segment"
        onClick={(e) => {
          setShowDeleteSegmentModalVisible(true);
          setSelectedSegment(segment);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Col style={{ backgroundColor: "white" }} span={11}>
      <Row
        justify="start"
        style={{
          alignItems: "baseline",
          padding: "20px",
          margin: "0px",
        }}
        gutter={16}
      >
        {isUserInAllowedGroups(userGroups, [
          ALL_GROUPS.TECH,
          ALL_GROUPS.PRODUCT,
        ]) && <></>}
        <Col>
          <Typography.Title level={5}>
            <TeamOutlined /> Add Segments
          </Typography.Title>
        </Col>

        <Col>
          <SegmentCreationDropdown refreshPageInfo={refreshPageInfo} />
        </Col>
        <Col>
          <Button>
            <Link
              to={`/decision-makers-grid/project/${project?.id}`}
              target="_blank"
            >
              View Decision Makers
            </Link>
          </Button>
        </Col>
        <Col flex="auto" style={{ textAlign: "right", padding: "0px" }}>
          {isProjectLoading || !project ? (
            <>
              <Skeleton.Button size="small" style={{ width: "175px" }} />
            </>
          ) : (
            <>
              {target?.p_id ? (
                <>
                  <Tooltip title={`There is an P Id assigned to this target`}>
                    <Tag color={"blue"}>P Id Assigned</Tag>
                  </Tooltip>
                </>
              ) : null}
              {s3DataUploadLogs.length > 0 ? (
                <Tooltip title={"Project has been exported at least once"}>
                  <Tag color={"green"}>
                    <CheckOutlined /> Exported
                  </Tag>
                </Tooltip>
              ) : (
                <Tooltip title={"Project has not been exported"}>
                  <Tag color={"green"}>
                    <CloseOutlined /> Not exported
                  </Tag>
                </Tooltip>
              )}
              {project?.status === "COMPLETED" ? (
                <Tooltip
                  title={`This project is marked as 'Completed' and cannot be edited.`}
                >
                  <Tag color={"green"}>
                    <LockOutlined /> Completed
                  </Tag>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`This project is marked as 'In Progress' and can be edited and changed.`}
                >
                  <Tag color={"orange"}>
                    <UnlockOutlined /> In progress
                  </Tag>
                </Tooltip>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row
        style={{
          paddingLeft: "20px",
          paddingBottom: "20px",
          alignItems: "baseline",
        }}
        gutter={16}
      >
        {
          <>
            <Col>
              <Badge
                style={{ backgroundColor: "#40a9ff" }}
                count={
                  selectedTargetId === "all"
                    ? globalFilterCount
                    : targetFilterCount
                }
              >
                <Button
                  icon={<FilterOutlined />}
                  onClick={() => {
                    setSelectedSegment();
                    setIsGlobalFilter(selectedTargetId === "all");
                    setShowAudienceMemberFilterDrawer(true);
                  }}
                >
                  Filter
                </Button>
              </Badge>
            </Col>
            <Col style={{ color: "#656565", fontSize: "12px" }}>
              {selectedTargetId === "all" ? (
                <>
                  Global Filters &nbsp;
                  <Tooltip title="applied to all Audience Members in this Project">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              ) : (
                <>
                  Decision Maker Filters &nbsp;
                  <Tooltip title="applied to all Audience Members for this Decision Maker">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              )}
            </Col>

            <Col style={{ color: "#656565", fontSize: "12px" }}>
              {selectedTargetId === "all" ? (
                <>
                  <Button
                    onClick={() => {
                      setSelectedSegment();
                      setIsGlobalFilter(selectedTargetId === "all");
                      setShowHVCConfigDrawer(true);
                    }}
                  >
                    HVC Config
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Col>

            <Col style={{ color: "#656565", fontSize: "12px" }}>
              {selectedTargetId === "all" ? (
                <>
                  <Badge
                    style={{ backgroundColor: "#40a9ff" }}
                    count={inExListCount}
                  >
                    <Button
                      onClick={() => {
                        setSelectedSegment();
                        setIsGlobalFilter(selectedTargetId === "all");
                        setshowInExclusionListDrawer(true);
                      }}
                    >
                      In/Exclusion List
                    </Button>
                  </Badge>
                </>
              ) : (
                <></>
              )}
            </Col>
          </>
        }
      </Row>
      {isAudienceSegmentsLoading ? (
        <Space
          direction="vertical"
          size={32}
          className="segment-loading-skeleton full-width-loading-skeleton"
        >
          <Skeleton.Input style={{ height: "65px", width: "100%" }} active />
          <Skeleton.Input style={{ height: "65px", width: "100%" }} active />
          <Skeleton.Input style={{ height: "65px", width: "100%" }} active />
          <Skeleton.Input style={{ height: "65px", width: "100%" }} active />
        </Space>
      ) : selectedTargetSegments.length > 0 ? (
        <Space direction="vertical">
          {selectedTargetSegments
            .sort((a, b) => {
              if (a.type_name > b.type_name) {
                return 1;
              } else if (a.type_name < b.type_name) {
                return -1;
              } else {
                return 0;
              }
            })
            .filter((segment) => segment.audience_member_count !== 0)
            .map((segment) => (
              <Row className="audience-segment-panel" key={segment.id}>
                <Col
                  style={{
                    fontSize: "1.25em",
                    fontWeight: "500",
                    alignSelf: "center",
                    paddingLeft: "20px",
                  }}
                  span={8}
                >
                  {segment.type_name}
                  {!Object.values(defaultSegmentNames).includes(
                    segment.type_name
                  ) && (
                    <span style={{ color: "#fa8c16", fontSize: "12px" }}>
                      &nbsp;&nbsp;
                      <Tooltip title="this is a non-standard segment name, Audience Members in this segment will not be sorted on export">
                        <WarningOutlined />
                      </Tooltip>
                    </span>
                  )}
                  {segment.first_degree_count === 0 &&
                    filteredFirstDegreeSegmentCounts?.[segment.type_name] !==
                      segment.first_degree_count && (
                      <span style={{ color: "#fa8c16", fontSize: "12px" }}>
                        &nbsp;&nbsp;
                        <Tooltip title="This segment is created by HVC. You will not be able to see this segment under specific target until you have Audience Members of the same segment for that target">
                          <WarningOutlined />
                        </Tooltip>
                      </span>
                    )}
                </Col>
                <Col span={13}>
                  <Descriptions
                    size="small"
                    column={3}
                    layout="vertical"
                    colon={false}
                  >
                    <Descriptions.Item
                      className="project-description-title"
                      label="1ST FLTR | AVAIL | HVC"
                    >
                      <span className="secondary-color">
                        {isBreakdownLoading ? (
                          <Skeleton.Avatar
                            style={{ height: "20px", width: "15px" }}
                            shape="square"
                          />
                        ) : (
                          filteredFirstDegreeSegmentCounts?.[
                            segment.type_name
                          ]?.toLocaleString() || 0
                        )}
                      </span>
                      &nbsp;| &nbsp;
                      {segment.first_degree_count?.toLocaleString()}
                      &nbsp;|&nbsp;
                      {isBreakdownLoading ? (
                        <Skeleton.Avatar
                          style={{ height: "20px", width: "15px" }}
                          shape="square"
                        />
                      ) : (
                        filteredFirstDegreeHVCSegmentCounts?.[
                          segment.type_name
                        ]?.toLocaleString() || 0
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      className="project-description-title"
                      label="2ND FLTR | AVAIL"
                    >
                      <span className="secondary-color">
                        {isBreakdownLoading ? (
                          <Skeleton.Avatar
                            style={{ height: "20px", width: "15px" }}
                            shape="square"
                          />
                        ) : (
                          filteredSecondDegreeSegmentCounts?.[
                            segment.type_name
                          ]?.toLocaleString() || 0
                        )}
                      </span>
                      &nbsp;|&nbsp;
                      {segment.second_degree_count?.toLocaleString()}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={3} style={{ alignSelf: "center" }}>
                  <Row justify="space-around">
                    {selectedTargetId !== "all" && (
                      <Badge
                        style={{ backgroundColor: "#40a9ff" }}
                        count={
                          // audience member filters count
                          audienceMemberFilters?.[selectedTargetId]?.[
                            segment.id
                          ]
                            ? Object.values(
                                audienceMemberFilters[selectedTargetId][
                                  segment.id
                                ]
                              ).reduce(
                                (agg, value) =>
                                  agg + value?.filter_params_count,
                                0
                              )
                            : 0
                        }
                      >
                        <Button
                          icon={<FilterOutlined />}
                          onClick={(e) => {
                            setSelectedSegment(segment);
                            setIsGlobalFilter(false);
                            setShowAudienceMemberFilterDrawer(true);
                          }}
                        />
                      </Badge>
                    )}
                    <Dropdown overlay={segmentDropdown(segment)}>
                      <Button style={{ padding: "0 7px" }}>
                        <EllipsisOutlined />
                      </Button>
                    </Dropdown>
                  </Row>
                </Col>
              </Row>
            ))}
        </Space>
      ) : (
        <Result
          icon={<TeamOutlined className="empty-results-icon" />}
          subTitle={
            <>
              Click the &nbsp;
              <PlusOutlined className="empty-results-button" />
              &nbsp; in this section to add Audience Members
            </>
          }
        />
      )}

      <QueryEditorDrawer
        isVisible={showQueryEditorDrawer}
        setIsVisible={setShowQueryEditorDrawer}
        selectedSegment={selectedSegment}
        refreshPage={refreshPageInfo}
      />

      {/* Filter drawer */}
      <AudienceMemberFilterDrawer
        isVisible={showAudienceMemberFilterDrawer}
        setIsVisible={setShowAudienceMemberFilterDrawer}
        isGlobalFilter={isGlobalFilter}
        segments={selectedTargetSegments}
        segment={selectedSegment}
        refreshPage={() => {
          dispatch({ type: "set-audience-breakdown-outdated" });
        }}
      />

      {/* Project config drawers */}
      <HVCConfigDrawer
        isVisible={showHVCConfigDrawer}
        setIsVisible={setShowHVCConfigDrawer}
      ></HVCConfigDrawer>

      <InExclusionListDrawer
        isVisible={showInExclusionListDrawer}
        setIsVisible={setshowInExclusionListDrawer}
        setInExListCount={setInExListCount}
      />

      {/* Delete Segment Modal */}
      <DeleteSegmentModal
        segment={selectedSegment}
        isVisible={showDeleteSegmentModalVisible}
        setIsVisible={setShowDeleteSegmentModalVisible}
      />
    </Col>
  );
};
